.header-pages {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  .title {
    margin-top: 0;
    margin-bottom: 8px;
    color: var(--bluegray-800);
    &:not(:first-child) {
      margin-top: 8px;
    }
  }

  .title-icon {
    color: var(--bluegray-800);
  }

  .title-icons {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  .subtitle {
    margin-top: 0;
    font-weight: 300;
    color: var(--surface-700);
  }
}
