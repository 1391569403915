@mixin header-links {
  a {
    color: var(--text-color);

    &:hover {
      color: var(--blue-600);
    }
  }
}

.header {
  width: 100%;
  position: fixed;
  z-index: 999;
  background: white;
  box-shadow: 0 -6px 4px 4px var(--surface-600);

  &.unauth {
    position: static;

    .header-content {
      margin: 0 auto;
      max-width: 1024px;
    }
  }

  .header-content {
    height: 48px;
    display: flex;
    align-items: center;
  }

  .menu-btn {
    margin: 0 0 0 24px;

    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: transparent;
    color: black;

    &:hover {
      border-color: var(--bluegray-300);
      background: var(--bluegray-50);
      color: black;
    }

    &:focus {
      box-shadow: 0 0 0 0;
    }

    svg {
      display: block;
      margin: auto;
      width: 14px;
      height: 14px;
    }
  }

  .logo {
    display: inline-block;
    height: 100%;

    img {
      margin: 0 14px;
      padding: 8px 0;
      width: auto;
      height: 100%;
    }
  }

  .left-section {
    @include header-links;
  }

  .right-section {
    display: flex;
    align-items: center;
    margin: 0 24px 0 auto;
    @include header-links;
  }

  .menu-button {
    color: var(--text-color) !important;
    outline: 0 !important;
    border: none !important;
    box-shadow: none !important;
    &:focus {
      outline: 0 !important;
    }
    &:active {
      outline: none;
      border: none;
    }
  }
}

.flag {
  width: 16px;
  height: 16px;
}

$countries: 'mk', 'rs', 'ru', 'be', 'bg', 'cz', 'dk', 'de', 'ee', 'ie', 'gr',
  'es', 'fr', 'hr', 'it', 'cy', 'lv', 'lt', 'lu', 'hu', 'mt', 'nl', 'at', 'pl',
  'pt', 'ro', 'sl', 'sk', 'fi', 'se', 'is', 'li', 'no', 'ch', 'gb', 'me', 'al',
  'tr', 'ba', 'xk', 'ua', 'ge', 'md', 'am', 'az', 'cn', 'br', 'au', 'ca', 'jp',
  'mx', 'tw', 'us', 'sg', 'kr';

@each $country in $countries {
  .#{$country}-flag {
    content: url('../../assets/img/flags/#{$country}.png') !important;
  }
}

button.p-button.p-component.p-splitbutton-defaultbutton {
  align-items: center;
}
