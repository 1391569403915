.section {
  list-style: none;
  margin: 0;
  padding: 0;

  &:not(:last-child) {
    border-bottom: 1px solid var(--surface-300);
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
  }

  menu {
    margin: 0;
    padding: 0;
  }

  .title {
    margin: 0.75rem 1rem;
    color: var(--text-color-secondary);
    display: inline-block;
  }
}
