.roles-page-edit-dialog {
  .access-roles-grid {
    display: grid;
    grid-template-columns: minmax(auto, 30%) minmax(auto, 30%) minmax(auto, 30%) min-content;
    gap: 10px;
  }

  .access-roles-grid-header {
    margin: 0 -8px;
    padding: 8px 12px;
    background: var(--bluegray-50);
    font-weight: bold;
  }

  .p-field {
    margin: 0;
  }
}
