.list-types-create-edit-dialog {
  max-width: 100%;

  &:not(.p-dialog-maximized) {
    height: 90%;

    .basic-config-tab {
      width: 480px;
      max-width: 100%;
    }

    .field-mapping-tab {
      width: 768px;
      max-width: 100%;
    }
  }

  .p-dialog-content {
    height: 100%;
  }

  @media screen and (min-width: 768px) {
    .p-md-6:nth-child(odd) {
      padding-right: 14px;
    }

    .p-md-6:nth-child(even) {
      padding-left: 14px;
    }
  }

  .divider {
    margin: 0 8px;
  }

  .info-icon {
    display: inline;
    margin-left: 8px;
    color: var(--bluegray-300);
  }

  .basic-config-tab {
    max-width: 480px;

    .customer-role {
      & > label {
        margin-bottom: 10px;
      }
    }
  }

  .field-mapping-tab {
    input {
      min-width: 160px;
    }

    table {
      width: 100%;

      th {
        padding-bottom: 4px;
      }

      td {
        padding-bottom: 6px;

        &:not(:last-child) {
          padding-right: 6px;
        }
      }
    }

    .no-fields {
      margin: 24px 0 0 0;
      display: block;
      text-align: center;

      p {
        font-size: 16px;
      }

      img {
        width: 300px;
      }
    }

    .list-type-field {
      padding: 4px 0;
      display: flex;

      & > div {
        &:not(:last-child) {
          margin-right: 8px;
        }
      }
    }

    .add-field-btn {
      padding-top: 8px;
    }
  }
}
