.login-page {
  .form-wrapper {
    margin: 48px auto 24px auto;
    max-width: 480px;
    background: white;
    border-radius: 3px;
    overflow: hidden;
  }

  .login-error {
    margin: 32px 32px -8px 32px;
    padding: 12px 8px;
    color: var(--red-500);
    background: var(--red-50);
    border: 1px solid var(--red-500);
    border-radius: 3px;
    text-align: center;
    font-weight: bold;
  }
}
