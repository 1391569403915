@use '../../../styles/z-index.scss';

%sideMenuShared {
  padding: 0;
  max-width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 48px);
  margin-top: 48px;
  position: fixed;
  background-color: white;
  z-index: z-index.$sideMenu;
}

%inDevTitleShared {
  text-align: center;
  color: var(--surface-600);
  text-transform: uppercase;
}

.sideMenuExpanded {
  @extend %sideMenuShared;
  width: 20rem;
  transition: width 0.3s ease-in-out;

  .title {
    text-align: center;
    color: var(--bluegray-700);
    background: var(--bluegray-50);
  }

  .inDevelopmentTitle {
    @extend %inDevTitleShared;
    margin: 3rem 1rem 1rem 1rem;
  }
}

.sideMenu {
  @extend %sideMenuShared;
  width: 5rem;
  transition: width 0.3s ease-in-out;
  box-shadow: 1px 1px 1px var(--surface-100);

  .title {
    text-align: center;
    color: var(--bluegray-700);
    background: var(--bluegray-50);
  }

  .inDevelopmentTitle {
    @extend %inDevTitleShared;
  }
}

.sideMenuMobile {
  padding: 0;
  width: 25rem;
  max-width: 100%;
  overflow-y: auto;
  z-index: 1000000;
  padding-bottom: 6.5rem;
  height: max-content;

  .title {
    text-align: center;
    color: var(--bluegray-700);
    background: var(--bluegray-50);
  }

  .inDevelopmentTitle {
    margin: 3rem 1rem 1rem 1rem;
    text-align: center;
    color: var(--bluegray-500);
    text-transform: uppercase;
  }
}

.itemsWrapper {
  margin-bottom: 3.5rem;
}

%menuButtonShared {
  position: fixed;
  bottom: 0;
  background-color: var(--surface-50) !important;
  border: 0;
  border-radius: 0;
  color: var(--surface-700);
  transition: width 0.3s ease-in-out;
  height: 40px;

  &:focus {
    box-shadow: 0 0 0 0 transparent;
  }

  &:hover {
    background: var(--surface-100);
    color: var(--surface-600);
  }
}

button.menuButtonExpanded {
  @extend %menuButtonShared;
  width: 20rem;
}

button.menuButton {
  @extend %menuButtonShared;
  width: 5rem !important;
  padding: 0.5rem 2rem !important;
  display: block;
}

.sidebarMenu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
