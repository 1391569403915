.imported-lists-view-import-dialog {
  .log-dialog-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .info-value {
      margin: 0;
      font-weight: 600;
    }
  }
}

.import-log-dt .warning {
  background-color: var(--yellow-100) !important;
}

.import-log-dt .error {
  background-color: var(--red-100) !important;
}
