@use '../../../styles/z-index.scss';

.popUpSubMenu {
  position: fixed;
  left: 53px;

  z-index: z-index.$popUpSubMenu;
  background-color: white;
  box-shadow: 1px 1px 3px var(--surface-400), 4px 4px 12px var(--surface-200);
  padding-bottom: 12px;

  .title {
    background-color: var(--surface-100);
    color: var(--surface-800);
  }
}
