.expandedFilters {
  position: relative;
  transition: height 0.5s ease;
}

.disabledOverflow {
  overflow: hidden !important;
}

.sidebarItem {
  padding: 10px;
  background: white;
  border: 2px solid var(--surface-d);
  border-radius: 3px;
  display: grid;
  grid-template-rows: auto 1fr auto;
  overflow: auto;

  &:last-of-type {
    margin-bottom: 0;
  }

  .childrenWrapper {
    & > *:last-child {
      margin-bottom: 0;
    }
  }

  .sidebarTitle {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    margin: 0 0;
    color: var(--text-color);
    position: relative;
    height: 30px;
  }

  .resetButton {
    position: absolute;
    top: -6px;
    right: 0;
  }

  .arrowIcon {
    position: absolute;
    left: 50%;
  }
}
