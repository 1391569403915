.delivery-attempts-list-page {
  .processing {
    color: var(--blue-400);
  }

  .processed {
    color: var(--green-400);
  }

  .processed_with_errors {
    color: var(--red-500);
  }

  .processed_with_warnings {
    color: var(--orange-400);
  }

  .failed {
    color: var(--red-500);
  }
}

.row-cursor-pointer .p-datatable-tbody > tr {
  cursor: pointer;
}
