.regions-add-edit-courier-dialog {
  width: 480px;
  max-width: 100%;

  .assignments-wrapper {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    gap: 8px 0;
    justify-items: center;

    & > span {
      justify-self: end;

      &:nth-child(2),
      &:nth-child(3) {
        justify-self: center;
      }
    }
  }

  .header-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-self: end;
    align-items: center;

    .header-item {
      margin: 0 0 0 13px;

      &:last-child {
        margin: 0 0 0 27px;
      }
    }
  }

  .side-item {
    margin: 8px 0 14px 0;
  }

  .inner-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }

  .field-container {
    margin-top: 9px;
    justify-self: end;
  }

  .info-item {
    margin-bottom: 16px;

    h4 {
      margin: 0 0 4px 0;
      color: var(--blue-500);
    }
  }
}
