.multi-select-auto-complete-input {
  .listbox {
    opacity: 0;
    max-height: 0;
    position: absolute;
    overflow: hidden;
    transition: all 0.2s;
    z-index: 10;

    &.shown {
      opacity: 1;
      max-height: unset;
      overflow-y: auto;
    }
  }
}
