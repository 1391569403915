.filters-reconciliation {
  padding: 10px;
  background: white;
  border: 1px solid var(--surface-d);
  border-radius: 3px;
  display: block;
  // grid-template-rows: repeat(1, 1fr);
  overflow: auto;
  // nova tema iskomentiraj
  // box-shadow: -1px 0px 6px 0px lightgray;

  .filters-reconciliation-header {
    display: flex;
    justify-content: space-between;
    margin: 0 0;
    color: var(--text-color);
  }

  .filters-reconciliation-header-left {
    display: flex;
  }

  .filters-reconciliation-header-title {
    margin-left: 12px;
  }

  .filters-reconciliation-filters {
    width: 100%;
  }
}
