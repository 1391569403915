.settings-page {
  margin: 0 auto;

  .subtitle {
    margin-bottom: 24px;
  }

  .settings-container {
    display: grid;
    border-radius: 3px;
    background: white;

    @media only screen and (max-width: 768px) {
      display: block;
      height: 520px;
      .settings-content {
        padding-top: 48px;
      }
    }

    @media only screen and (max-width: 899px) {
      grid-template-columns: 300px auto;
      height: 520px;
    }

    @media only screen and (min-width: 900px) {
      height: 620px;
      grid-template-columns: 360px auto;
    }
  }

  .settings-content {
    padding: 10px;
    overflow: auto;

    height: 100%;

    form {
      margin: 24px 0;
    }
  }

  .settings-links-container {
    padding: 0px;
    margin: 0px;
    overflow-y: auto;
  }

  .link-container {
    display: flex;
    align-items: center;
  }

  .settings-link {
    display: inline-block;
    width: calc(100% - 16px);
    margin: 4px 8px 0 8px;
    padding: 8px;
    color: var(--bluegray-500);
    border-radius: 3px;

    .link-title-description {
      display: block;
    }

    .link-icon {
      margin-right: 12px;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    .link-title {
      color: var(--bluegray-900);
      font-weight: bold;
    }

    &:not(.active):hover {
      background: var(--surface-200);
      color: var(--bluegray-800);
    }

    &.active {
      background: var(--blue-50);
      color: var(--blue-600);
      cursor: default;

      .link-title {
        color: var(--blue-600);
      }
    }
  }

  .p-field,
  .field-with-errors {
    &:hover {
      .settings-tooltip {
        display: inline-block;
      }
    }
  }

  .settings-tooltip {
    margin-left: 8px;
    display: none;

    color: var(--bluegray-600);
  }

  .p-inputtext,
  .p-inputnumber,
  .p-dropdown {
    width: 100%;
  }

  .button-container {
    display: block;
    .p-button:not(:first-child) {
      margin-left: 6px;
    }
  }

  .hide-button-section {
    display: none;
  }

  .notifications-page {
    .notification,
    .notification-head {
      margin-bottom: 12px;
      display: grid;
      grid-template-columns: 3fr 1fr 1fr;
    }

    .notification {
      div {
        margin: 0 auto;
      }
    }

    .notification-head {
      margin-top: 12px;
      font-weight: bold;

      :not(:first-child) {
        text-align: center;
      }
    }
  }

  .p-tree {
    max-height: 400px;
    overflow-y: auto;
  }
}
