.custom-datatable-filters {
  .filters-container {
    display: grid;
    grid-template-rows: 1fr auto;
    grid-gap: 10px;
    text-align: left;
    align-items: baseline;
  }

  .cols-two {
    grid-template-columns: repeat(2, 1fr);
  }

  .cols-three {
    grid-template-columns: repeat(3, 1fr);
  }

  .cols-four {
    grid-template-columns: repeat(4, 1fr);
  }

  .cols-five {
    grid-template-columns: repeat(5, 1fr);
  }

  .filter {
    display: flex;
    flex-direction: column;
  }
}

.custom-datatable {
  .table-header-container {
    display: grid;
    grid-template-columns: max-content auto max-content;
  }

  .filter-chips-container {
    margin: auto 0;
    display: inline-flex;
    overflow: auto;

    @media (max-width: 992px) {
      visibility: hidden;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .filter-chip {
    white-space: nowrap;
    overflow: unset;
  }
}

.p-datatable .p-datatable-tbody > tr.p-highlight-contextmenu {
  font-weight: bold;
  filter: brightness(90%);
}
