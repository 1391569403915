.employees-qr-dialog {
  h4 {
    margin: 0 0 4px 0;
    color: var(--blue-500);
  }

  .qr-code {
    width: 256px;
    height: auto;
  }

  .info-item {
    margin-bottom: 16px;
  }
}
