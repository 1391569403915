.view-delivery-attempts-dialog {
  &:not(.loading) {
    width: 600px;
    height: 90%;
  }

  .p-dialog-content {
    height: 100%;
  }

  h4 {
    margin: 24px 0 10px 0;
    color: var(--blue-500);
  }

  table {
    width: 100%;

    tr {
      &:nth-child(even) {
        background: var(--bluegray-50);
      }

      &.section {
        background: white;

        td {
          padding: 0 0 8px 0;
        }
      }
    }

    td {
      margin: 0;
      padding: 6px 12px;

      &:not(:first-child) {
        text-align: center;
      }
    }
  }

  .inner-table {
    margin: 0;
    padding: 0;

    td {
      margin: 0;
      padding-left: 30px;
      padding-right: 118px;
    }
  }
}
