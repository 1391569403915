.employees-view-dialog {
  width: 600px;

  .quick-info-container {
    display: flex;
    justify-content: space-evenly;

    .profile-picture {
      margin: 0 12px 0 6px;
      align-self: center;
      text-align: center;

      svg {
        margin-bottom: 6px;
        width: 36px;
        height: auto;
      }
    }

    .quick-info {
      margin-left: 12px;
      align-self: center;
      margin-bottom: 6px;

      & > div {
        margin: 6px 0;

        svg {
          margin-right: 6px;
          width: 16px;
        }
      }
    }
  }

  h4 {
    margin: 24px 0 4px 0;
    color: var(--blue-500);
  }

  svg {
    color: var(--bluegray-800);
  }
}
