.orders-history-page {
  .green td {
    background-color: var(--bluegray-100);
  }

  .red td {
    background-color: var(--red-100);
  }

  .yellow td {
    background-color: var(--yellow-200);
  }

  .header-buttons {
    display: flex;
    flex-direction: column;
    justify-content: end;
  }
}
