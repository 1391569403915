.clients-create-edit-dialog {
  &:not(.p-dialog-maximized):not(.loading) {
    width: 800px;
    height: 90%;
  }

  .p-dialog-content {
    height: 100%;
  }

  @media screen and (min-width: 768px) {
    .p-md-6:nth-of-type(odd) {
      padding-right: 14px;
    }

    .p-md-6:nth-of-type(even) {
      padding-left: 14px;
    }
  }

  .divider {
    margin: 0 8px;
  }

  .working-hours-wrapper {
    display: flex;
    align-items: center;
  }

  .general-info-tab {
    .street-numbers {
      margin-top: 12px;
      display: flex;
      align-items: center;
    }
  }

  .legal-data-tab {
    .discount-fields-0 .p-field {
      padding-right: 4px;
    }

    .discount-fields-1 .p-field {
      padding-left: 2px;
      padding-right: 2px;
    }

    .discount-fields-2 .p-field {
      padding-left: 4px;
    }
  }

  .orders-tab {
    .orders-flags {
      margin-top: 12px;
    }

    .assigned-templates-container {
      margin-top: 8px;
    }

    .assigned-template {
      padding-left: 8px;
      display: flex;
      align-items: center;
      align-content: center;

      .assigned-template-name {
        margin-right: 12px;
      }

      .p-button {
        opacity: 0;
        transition: opacity 0.17s;
      }

      &:hover {
        .p-button {
          opacity: 1;
        }
      }
    }
  }
}

.departments {
  .charging-department {
    display: flex;
    margin: 8px 0;
    gap: 8px;

    .delete-department-btn {
      flex: 0 0 auto;
      align-self: flex-start;
      margin-top: 17px;
    }

    .department-name,
    .department-client {
      width: 100%;
      flex-grow: 1;
    }

    .department-error {
      color: var(--red-500);
    }
  }
}
