.imported-lists-import-from-file-dialog {
  width: 720px;

  .p-dialog-content {
    padding: 16px 24px 24px 24px;
  }

  .p-dialog-footer {
    padding: 24px;
    background-color: var(--bluegray-50);
    border-top: 1px solid var(--bluegray-100);
  }

  .import-from-file-form {
    background-color: white;
    width: 360px;

    .show-section {
      display: block;
    }

    .hide-section {
      display: none;
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .submit-btn {
        flex: 1 1 0;
        align-self: flex-end;
        display: flex;

        .p-button {
          align-self: flex-end;
        }
      }

      .upload-file {
        & > div > div > div {
          display: flex;
          align-items: flex-start;

          .p-fileupload-row > div {
            padding-top: 0;
            padding-bottom: 0;
          }
        }

        .p-fileupload-buttonbar button {
          display: none;
        }

        .p-fileupload .p-fileupload-buttonbar {
          background: none;
          padding: 0;
          border: none;
        }

        .p-fileupload .p-fileupload-content {
          background: none;
          padding: 0;
          border: none;
        }

        .p-fileupload-row {
          display: flex;
          flex-direction: row;
        }

        .p-fileupload-row div {
          width: 100%;
        }

        .p-fileupload-row div:nth-child(2) {
          display: none;
        }

        .p-fileupload-row div:nth-child(3) {
          width: 20px;
        }

        .p-fileupload .p-fileupload-buttonbar .p-button {
          color: var(--blue-500);
          background-color: white;
          border: 1px solid var(--blue-500);

          &:hover {
            color: white;
            background-color: var(--blue-500);
          }

          &:active {
            color: white;
            background-color: var(--blue-500);
          }
        }

        .p-fileupload-content .p-progressbar {
          width: 0;
        }

        .p-fileupload .p-fileupload-advanced .p-component {
          display: flex;
          align-items: center;
        }
      }

      .import-all-or-nothing,
      .overwrite-existing {
        margin-top: 16px;
      }
    }

    .street-numbers {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: 14px;

      .p-field {
        margin-bottom: 0;
      }
    }

    .client-and-list-type,
    .klient-is-sender {
      display: block;
    }

    .changeable-payment {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      gap: 12px 0;
      padding-top: 4px;

      span {
        font-weight: 500;
      }

      .p-field {
        margin-bottom: 0;
      }

      .p-field-checkbox,
      .p-field-radiobutton {
        margin-top: 14px;
      }
      .p-field-radiobutton:last-child {
        margin-bottom: 20px;
      }
    }

    .section-title {
      margin: 16px 0 8px 0;
      width: 100%;
      display: block;
      font-size: 16px;
      font-weight: bold;

      &:first-child {
        margin-top: 0;
      }
    }

    .section-subtitle {
      margin: 0 0 20px 0;
      width: 100%;
      display: block;
      font-size: 14px;
      color: var(--text-secondary-color);
      font-weight: 300;
    }
  }
}
