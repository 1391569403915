.detailsSection {
  box-shadow: -1px 0px 6px 0px lightgray;
  display: flex;
  flex-wrap: nowrap;
  gap: 4px;
  flex: 1;
  width: 100%;
  & > * {
    flex: 1;
  }
}
