.goBackLink {
  display: inline-block;
  cursor: pointer;

  & {
    color: var(--blue-500);
  }
  &:visited {
    color: var(--bluegray-500);
  }

  &:hover {
    color: var(--blue-600);
  }

  &:active {
    color: var(--blue-800);
  }
}
