.stats {
  .stats-section {
    display: flex;
    // flex-direction: column;
    justify-content: space-between;
    margin-bottom: 2px;
    font-size: small;
  }

  @keyframes skeletonAnimate {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .skeleton-content {
    display: flex;
    align-items: center;
    width: 50%;
    opacity: 0;
    animation-name: skeletonAnimate;
    animation-duration: 2000ms;
    animation-direction: forward;
    animation-delay: 0;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
  }

  @keyframes bgAnimate {
    0% {
      background-position: 50% 0;
    }
    100% {
      background-position: -150% 0;
    }
  }

  .skeleton {
    height: 10px;
    width: 60px;
    border-radius: 10px;
    background-image: linear-gradient(
      to right,
      hsla(210, 2%, 54%, 20%) 0%,
      hsla(210, 4%, 89%, 20%) 10%,
      hsla(210, 2%, 54%, 20%) 40%,
      hsla(210, 2%, 54%, 20%) 100%
    );
    background-repeat: repeat-x;
    background-size: 200% 100%;
    box-shadow: 0 4px 6px -1px hsla(0, 0%, 0%, 0.1),
      0 2px 4px -2px hsla(0, 0%, 0%, 0.1);
    animation: bgAnimate 2s linear infinite;
  }
}
