.filtersWrapper {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  grid-template-areas:
    'main'
    'footer';
  overflow: auto;

  .filterDiv {
    padding: 8px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    overflow-y: scroll;
    height: 100%;
    grid-area: main;
  }
}

.sidebarItem {
  padding: 8px;
  background: white;
  border: 1px solid var(--surface-d);
  border-radius: 3px;

  &:last-of-type {
    margin-bottom: 0;
  }

  .childrenWrapper {
    & > *:last-child {
      margin-bottom: 0;
    }
  }

  h3 {
    margin-top: 0;
    color: var(--text-color);

    svg:not(.tooltip > svg) {
      margin-right: 8px;
      color: var(--bluegray-400);
    }

    .tooltip {
      svg {
        margin-left: 8px;
        font-size: 12px;
        color: var(--blue-300);
      }
    }
  }

  p {
    margin-top: 0;
    margin-bottom: 4px;
  }
}
