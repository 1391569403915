$activeItemBorderWidth: 0.25rem;

.menuItem {
  display: block;
  text-align: left;

  .mainContent {
    padding: 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    color: var(--bluegray-800);
    cursor: pointer;

    &.active {
      cursor: default;
      border-left: $activeItemBorderWidth solid var(--bluegray-800);
      background-color: var(--bluegray-50);

      .icon {
        margin-left: -$activeItemBorderWidth;
      }
    }

    &:hover {
      background-color: var(--bluegray-100);
    }

    &.nestingLevel1 {
      &.active {
        background-color: var(--bluegray-100);
        border-left: $activeItemBorderWidth solid var(--bluegray-800);
        font-weight: bold;
      }
      padding-left: 2.5rem;
    }

    &.nestingLevel2 {
      padding-left: 3.5rem;
    }

    .icon {
      margin-right: 0.5rem;
      width: 2rem;
      font-size: 1rem;
    }
  }

  .children {
    background-color: var(--bluegray-50);

    .mainContent {
      &:hover {
        background-color: var(--bluegray-200);
      }
    }
  }
}
