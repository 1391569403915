.statusBadge {
  margin-top: -2px;
  margin-bottom: -2px;
  display: inline-block;
  border-radius: 3px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;

  &.created {
    color: var(--bluegray-800);
    background-color: var(--bluegray-100);
  }

  &.approved {
    color: var(--blue-900);
    background-color: var(--blue-300);
  }

  &.reversed {
    color: var(--red-900);
    background-color: var(--red-300);
  }

  &.paid {
    color: var(--green-900);
    background-color: var(--green-300);
  }
}
