.bulk-orders-list-page {
  .status-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 10px;
    letter-spacing: 0.3px;
    display: flex;
    justify-content: flex-start;
  }

  .orders-bulk-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .processing {
    color: var(--blue-400);
  }

  .processed {
    color: var(--green-400);
  }

  .processed_with_errors {
    color: var(--red-500);
  }

  .processed_with_warnings {
    color: var(--orange-400);
  }

  .failed {
    color: var(--red-500);
  }
}

.row-cursor-pointer .p-datatable-tbody > tr {
  cursor: pointer;
}
