.regions-view-region-dialog {
  width: 600px;
  max-width: 100%;

  .map {
    margin-bottom: 18px;
  }

  .map {
    width: 100%;
    height: 240px;

    .leaflet-marker-icon {
      cursor: auto;
    }
  }

  .no-data-available {
    font-weight: bold;
    font-style: italic;
    color: var(--red-500);
  }

  .info-item {
    margin-bottom: 16px;

    h4 {
      margin: 0 0 4px 0;
      color: var(--blue-500);
    }
  }

  .description-content {
    white-space: pre-wrap;
  }
}
