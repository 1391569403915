%indicator {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
  display: inline-block;
  border-radius: 9999px;
  outline: var(--surface-400) solid 0.5px;
}

.legend_container {
  display: flex;
  flex-direction: column;
}

.legend_item {
  justify-items: center;
  align-items: center;

  &__title {
    color: var(--gray-600);
  }

  &__indicator-green {
    background: var(--green-200);
    @extend %indicator;
  }

  &__indicator-blue {
    background: var(--blue-100);
    @extend %indicator;
  }

  &__indicator-dark-blue {
    background: var(--blue-200);
    @extend %indicator;
  }

  &__indicator-orange {
    background: var(--orange-100);
    @extend %indicator;
  }
}
