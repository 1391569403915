.info-header {
  width: 180px;
  font-weight: 500;
}

.shipment-tracking {
  font-size: 16px;
}

.datatable-responsive .p-datatable-tbody > tr {
  &:not(:last-child) {
    border-bottom: 1px solid var(--surface-300);
  }
}

.datatable-responsive .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

@media screen and (max-width: 600px) {
  .p-datatable {
    background-color: white;
  }
  .p-datatable.datatable-responsive .p-datatable-thead > tr > th,
  .p-datatable.datatable-responsive .p-datatable-tfoot > tr > td {
    display: none !important;
  }
  .p-datatable.datatable-responsive .p-datatable-tbody > tr {
    width: auto;
  }

  .p-datatable.datatable-responsive .p-datatable-tbody > tr > td {
    text-align: left;
    display: inline-block;
    width: max-content;
    clear: left;
    border: 0 none;
  }

  .p-datatable.datatable-responsive
    .p-datatable-tbody
    > tr
    > td
    .p-column-title {
    padding: 0.4rem;
    min-width: 60px;
    display: inline-block;
    margin: -0.4em 1em -0.4em -0.4rem;
    font-weight: bold;
  }

  .p-datatable.datatable-responsive
    .p-datatable-tbody
    > tr
    > td
    .p-column-value {
    width: 100%;
    overflow-y: auto;
  }
}
