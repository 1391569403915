.employees-create-edit-dialog {
  &:not(.p-dialog-maximized) {
    width: 480px;
    height: 90%;
  }

  .p-dialog-content {
    height: 100%;
  }

  @media screen and (min-width: 768px) {
    .p-md-6:nth-child(odd) {
      padding-right: 14px;
    }

    .p-md-6:nth-child(even) {
      padding-left: 14px;
    }
  }

  .divider {
    margin: 0 8px;
  }

  .notification,
  .notification-head {
    margin-bottom: 12px;
    display: grid;
    grid-template-columns: 3fr 1fr 1fr;
  }

  .notification-head {
    margin-top: 12px;
    font-weight: bold;
  }
}
