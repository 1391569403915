.map {
  min-height: 480px;
  height: calc(100vh - 199px);
  border-radius: 3px;
}

.mapSettings {
  padding: 16px;
  background: white;
  border-radius: 3px;

  .apiStatusInfo {
    margin-bottom: 12px;
    padding: 8px 12px;
    border-radius: 3px;

    span {
      margin-left: 6px;
    }
  }

  .noCouriersFound {
    background: var(--yellow-500);
  }

  .httpError {
    color: white;
    background: var(--red-500);
  }
}

.mapLegend {
  .mapLegendItem {
    margin: 6px 0;
    display: flex;
    align-items: center;

    &:first-child {
      margin-top: 8px;
    }
  }

  img {
    width: 30px;
    height: auto;
  }

  .label {
    margin-left: 6px;
  }

  .duration {
    color: var(--bluegray-600);
    margin-left: 6px;
    font-size: 12px;
  }
}

.popupTitle,
.popupValue {
  display: inline-block;
}

.popupTitle {
  margin-right: 4px;
  color: var(--bluegray-600);
}

.popupValue {
  font-weight: bold;
}
