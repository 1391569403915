.hubs-reports-page {
  overflow: hidden;
  height: 100%;
  .hubs-reports-items {
    overflow: auto;
    margin-top: 10px;
  }
  .empty-reports {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    color: var(--bluegray-800);
  }
}
