.courier-liabilities-create-edit-dialog {
  min-width: 330px;
  max-width: 600px;

  .courier-employee-date {
    width: 300px;
  }

  .addresses {
    margin-top: 24px;

    table {
      margin: 0;
      padding: 0;

      thead {
        th {
          font-size: 12px;
          padding: 4px 0;
          margin: 0;
          font-weight: normal;
          text-align: start;
        }
      }

      td {
        margin-bottom: 8px;

        &:first-child {
          max-width: 130px;
        }
      }
    }
  }
}
