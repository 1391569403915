.courier-liabilities-view-addresses-dialog {
  &:not(.p-dialog-maximized) {
    max-width: 100%;
    width: 600px;
    overflow: hidden;
  }

  .p-dialog-content {
    height: 90vh;
  }

  .addresses {
    min-width: 100%;
    margin-top: 10px;
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    .address {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      align-content: center;
      justify-content: center;
      margin: 16px;

      svg {
        font-size: 48px;
      }

      p {
        margin-bottom: 0;
      }
    }

    .created {
      color: var(--blue-500);
    }

    .uncreated {
      color: var(--red-500);
    }

    .created-for-client {
      color: var(--bluegray-500);
    }
  }
}
