.error-dialog {
  .p-dialog-header {
    color: var(--red-500) !important;
  }

  .p-dialog-content {
    max-width: 480px;
    line-height: 1.5;
  }
}
