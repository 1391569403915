.logs-page {
  .red td {
    background-color: var(--red-50);
  }

  .yellow td {
    background-color: var(--yellow-50);
  }

  .blue td {
    background-color: var(--blue-50);
  }
}
