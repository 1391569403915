$borderColor: rgba(0, 0, 0, 0.05);

.react-table {
  color: var(--text-color);
  font-family: var(--font-family);

  input[type='checkbox'] {
    margin: 4px 0 0 0;
    padding: 0;
  }

  input[type='text'] {
    width: 100%;
    padding: 6px;
    color: var(--text-color);
    border: 1px solid var(--surface-400);
    border-radius: 3px;
    transition: border-color 0.2s, box-shadow 0.2s;
    outline: none;
    outline-offset: 0;
    box-shadow: 0 0 0 2px transparent;

    &:hover {
      border-color: var(--blue-400);
    }

    &:focus {
      border-color: var(--blue-400);
      box-shadow: 0 0 0 2px var(--blue-200);
    }
  }

  .table-wrapper {
    box-shadow: 0 0 5px $borderColor;
    border: 1px solid $borderColor;
    border-radius: 3px;
    overflow: hidden;
  }

  .table-header {
    padding: 8px;
    background: var(--surface-50);

    h3 {
      margin: 0;
      padding: 0;
    }
  }

  .table-footer {
    padding: 12px;
    background: var(--surface-50);
    font-weight: 600;
  }

  .table-container {
    width: 100%;
    overflow: auto;

    .table {
      display: inline-block;
      border: none;

      .thead {
        position: sticky;
        top: 0;
        z-index: 900;

        .th {
          padding: 8px;
          border-width: 1px 1px 1px 0;
          border-style: solid;
          border-color: $borderColor;
          background: var(--surface-50);
          font-weight: 600;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          &:last-child {
            border-right: none;
          }

          svg {
            margin-left: 4px;
          }
        }
      }

      .tbody {
        .tr {
          background: var(--surface-50);

          &:hover,
          &.context-menu-selected-row {
            background: var(--bluegray-100) !important;
          }

          &.row-even:not(.selected-row):not(.expected-reconciliation-row):not(.reconciled-row) {
            background: white;
          }

          &.selected-row {
            background: var(--blue-100);
          }

          &.expected-reconciliation-row:not(.selected-row) {
            background: var(--orange-50);
          }

          &.reconciled-row {
            background: var(--green-100);
          }
        }

        .td {
          padding: 8px;
          border: 1px solid $borderColor;
          border-top: none;
          border-left: none;
          white-space: nowrap;
          height: 38px;
          line-height: 19px;

          &,
          * {
            overflow: hidden;
            text-overflow: ellipsis;
          }

          &:last-child {
            border-right: none;
          }

          .editable-cell-input {
            margin: -6px auto;
          }
        }
      }
    }
  }
}
