// Generates classes utilizing prime-react color palettes
@mixin bg($color, $shade) {
  .bg-#{$color}-#{$shade} {
    @if $shade >= 500 {
      color: white !important;
    }

    background-color: var(--#{$color}-#{$shade}) !important;

    &.p-selectable-row:hover {
      filter: brightness(90%);
    }
  }
}

// Generates classes utilizing prime-react color palettes
@mixin color($color, $shade) {
  .color-#{$color}-#{$shade} {
    color: var(--#{$color}-#{$shade}) !important;
  }
}

@mixin entirePalette($color) {
  @include bg($color, 50);
  @include color($color, 50);

  @for $i from 1 through 9 {
    @include bg($color, $i * 100);
    @include color($color, $i * 100);
  }
}

@include entirePalette('blue');
@include entirePalette('green');
@include entirePalette('yellow');
@include entirePalette('cyan');
@include entirePalette('pink');
@include entirePalette('indigo');
@include entirePalette('teal');
@include entirePalette('orange');
@include entirePalette('bluegray');
@include entirePalette('purple');
@include entirePalette('red');
