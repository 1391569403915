.rhf-field-with-errors {
  &.invalid {
    input,
    .p-dropdown {
      border-color: var(--red-500);
    }
  }

  span.error {
    margin-top: 4px;
    display: inline-block;
    font-size: 12px;
    color: var(--red-500);
  }
}
