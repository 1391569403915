.cod-create-warrants-page {
  .client-tree {
    max-height: calc(100vh - 367px);
    overflow-y: auto;

    .p-treenode-label {
      width: 100%;
    }

    .tree-node {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .stats {
      text-align: center;
    }
  }
}
