:root {
  // TODO: remove this after primereact v8 upgrade
  --text-secondary-color: #6c757d;
  --red-50: #fff5f5;
  --red-100: #ffd0ce;
  --red-200: #ffaca7;
  --red-300: #ff8780;
  --red-400: #ff6259;
  --red-500: #ff3d32;
  --red-600: #d9342b;
  --red-700: #b32b23;
  --red-800: #8c221c;
  --red-900: #661814;
}

html,
body {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: var(--blue-500);

  &:hover {
    color: var(--blue-700);
  }
}

.main-btn {
  margin-bottom: 16px !important;

  &:not(:last-of-type) {
    margin-right: 8px;
  }
}

.sidebar_filter {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  &_row {
    display: flex;
    gap: 0.5rem;
  }

  & > label {
    margin-bottom: 3px;
    font-size: smaller;
  }

  &_active {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem var(--yellow-100);
    border-color: var(--yellow-500) !important;
  }
}

.sidebar_filter_same_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.main-tooltip {
  opacity: 1 !important;
  box-shadow: 0 0 12px hsla(210, 11%, 15%, 0.48) !important;
  z-index: 9999999999 !important;
  max-width: 300px;
  word-break: unset;
}

.cursor-crosshair {
  cursor: crosshair !important;
}

.content-wrapper {
  padding: 60px 10px 10px 10px;
  flex-grow: 1;
  top: 0;
  right: 0;
  bottom: 0;
  transition: left 0.3s ease-in-out;
  background: var(--bluegray-100);
}

.page {
  background: #fafafa;
  padding: 8px 5px;
  box-shadow: -1px 0px 2px 0px white;
  border-radius: 5px;
}

@media only screen and (max-width: 768px) {
  .content-wrapper {
    padding: 50px 2px 2px 2px;
  }
  .page {
    padding: 2px;
  }
}

.p-checkbox + label {
  margin-left: 8px;
}

.dialog-section-divider {
  margin: 2px 0 12px 0;
  width: 100%;
  display: block;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  color: var(--bluegray-400);

  &:first-child {
    margin-top: 0;
  }
}

.form-divider {
  margin-top: 48px;
}

.checkbox-container {
  margin-bottom: 4px;
  display: flex;
  align-items: center;
}

.mt-0 {
  margin-top: 0;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.form-group-title {
  margin: 48px 0 14px 0;
  display: block;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  color: var(--bluegray-400);
}

.datatable-flex-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: normal;
}

.filter-overlay-badge {
  padding: 2px 6px;
  border-radius: 6px;
  margin-left: 6px;
  display: inline-block;
  color: var(--bluegray-700);
  background: var(--surface-200);
  font-size: 12px;
  font-weight: 500;

  &:hover {
    .p-badge {
      display: inline-block;
    }
  }

  .value {
    padding-left: 4px;
    display: inline-block;
    color: var(--bluegray-800);
    font-weight: 700;
  }

  .p-badge {
    top: 2px;
    right: 6px;
    min-width: 16px;
    height: 16px;
    display: none;
    line-height: 13px;
  }

  button {
    width: 10px !important;
    height: 10px !important;
    padding: 0 !important;
    margin: 0 !important;
    font-size: 10px;
    color: white !important;
  }
}

.secondary-icon {
  color: var(--surface-400);
}

.p-multiselect-filter {
  min-width: 120px;
}

.p-contextmenu {
  width: fit-content;
  min-width: 175px;
}

.p-menuitem-link .p-menuitem-icon {
  margin: 0 14px 0 4px !important;
  width: 16px;
}

.title-go-back-link {
  display: inline-block;
  cursor: pointer;

  &,
  &:visited {
    color: var(--bluegray-500);
  }

  &:hover {
    color: var(--blue-500);
  }

  &:active {
    color: var(--blue-800);
  }
}

.datatable-no-header {
  border-top: 1px solid #e9ecef !important;
}

.no-shadow {
  box-shadow: none !important;
}

.table-tabview {
  border-radius: 3px;
  border: 1px solid var(--surface-d);
  box-shadow: 0 0 5px var(--surface-200);
  overflow: hidden;
}

.datatable-dialog {
  max-width: 100vw;

  &:not(.p-dialog-maximized) {
    @media (min-width: 1024px) {
      max-width: 90vw;
    }
  }
}

.unselectable {
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.text-sm {
  font-size: 12px;
}

.p-tabview-nav {
  li.invalid {
    &.p-tabview-selected {
      a {
        border-color: var(--red-500) !important;
      }
    }

    a {
      color: var(--red-500) !important;

      i {
        margin-right: 6px;
      }
    }
  }
}

.p-col.p-field {
  padding-bottom: 0;
}

.number-group {
  .p-inputtext {
    width: 100% !important;
  }

  .p-dropdown-label {
    width: 1% !important;
  }
}

.accordionHeaderTemplate {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  transform: translateY(-1px);
}

.badge {
  border-radius: 10px;
  text-align: center;
  padding: 0 0.5rem;
  min-width: 1rem;
  height: 1rem;
  line-height: 1rem;
  font-size: 0.75rem;
  font-weight: bold;
  background: var(--yellow-500);
  color: #212529;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
