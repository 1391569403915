.listBox {
  opacity: 0;
  max-height: 0;
  position: absolute;
  overflow: hidden;
  transition: all 0.2s, top 0ms;
  z-index: 99999;

  &.shown {
    opacity: 1;
    max-height: unset;
    overflow-y: auto;
  }
}

.clearBtn {
  border-color: #ced4da !important;
  border-left: none !important;
}
