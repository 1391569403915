@use '../shared';

.item {
  width: shared.$width;
  padding: 0.75rem 1rem;
  position: relative;
  color: var(--text-color);
  background-color: white;
  list-style: none;
  user-select: none;

  &.separator {
    height: 1px;
    padding: 0;
    margin: 0.25rem 0;
    background: var(--surface-300);
  }

  &:not(.separator):hover {
    background: var(--surface-100);

    & > menu {
      display: unset;
      padding: 0.25rem 0;
    }
  }

  .container {
    display: flex;
    align-items: center;
    cursor: default;

    .icon {
      margin-right: 0.75rem;
      color: var(--text-color-secondary);
    }

    .chevron {
      margin-left: auto;
    }
  }

  menu {
    @include shared.menuItem;

    display: none;
    position: absolute;
    left: shared.$width;
    top: 0;
  }
}
