.regions-add-edit-region-dialog {
  width: 95%;
  height: 85%;

  @media (max-width: 992px) {
    width: 100%;
    height: 100vh;
  }

  .p-dialog-content {
    padding-bottom: 0;
    height: 100%;
  }

  .form-grid {
    @media (max-width: 767px) {
      flex-direction: column-reverse;
    }
  }

  .map {
    height: calc(80vh - 135px);

    @media (max-height: 767px) {
      height: calc(80vh - 150px);
    }
  }

  .invalid {
    .map {
      outline: 2px solid var(--red-500);
    }

    .position-switch-wrapper {
      margin: 8px 0 16px 0;
      display: flex;
      align-items: center;

      .p-inputswitch {
        margin-right: 6px;
      }
    }
  }
}
