.roles-page-view-dialog {
  table {
    width: 100%;

    tr {
      &:nth-child(even) {
        background: var(--bluegray-50);
      }

      &.section {
        background: white;

        td {
          padding: 0 0 8px 0;
        }

        h4 {
          margin: 24px 0 0 0;
          color: var(--blue-500);
        }
      }

      td {
        margin: 0;
        padding: 6px 12px;
      }
    }
  }
}
