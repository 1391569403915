.hubs-page-delete-hub-dialog {
  .native-hub-error-grid {
    display: grid;
    grid-template-columns: min-content auto;
    gap: 2rem;

    &:nth-child(1) {
      align-items: center;
      justify-content: center;
    }
  }
}
