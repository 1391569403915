$width: 13rem;

@mixin menuItem {
  margin: 0;
  padding: 0.25rem 0;
  width: $width;
  background-color: white;
  position: absolute;
  box-shadow: 1px 1px 3px var(--surface-400), 4px 4px 12px var(--surface-200);
}
