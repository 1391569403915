@mixin common {
  .total-parcel {
    display: block;
    margin-bottom: 4px;
    font-weight: bold;
    font-size: 14px;
  }

  .info-parcel-container {
    margin: 12px 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .info-title,
  .info-value {
    display: inline-block;
  }

  .info-title {
    margin-right: 4px;
    color: var(--surface-700);
  }

  .info-value {
    font-weight: bold;
  }

  .serial-no-link-btn {
    padding: 0;
    margin: 0;
  }
}

.parcel-map-page {
  @include common;

  .map {
    border-radius: 3px;
    height: calc(100vh - 199px);
  }

  .map-settings {
    padding: 16px;
    background: white;
    border-radius: 3px;
    overflow-y: auto;
    height: calc(100vh - 199px);

    .api-status-info {
      margin-bottom: 12px;
      padding: 8px 12px;
      border-radius: 3px;

      span {
        margin-left: 6px;
      }
    }

    .no-couriers-found {
      background: var(--yellow-500);
    }

    .http-error {
      color: white;
      background: var(--red-500);
    }
  }

  .map-legend {
    .map-legend-item {
      margin: 6px 0;
      display: flex;
      align-items: center;

      &:first-child {
        margin-top: 8px;
      }
    }

    img {
      width: 30px;
      height: auto;
    }

    .label {
      margin-left: 6px;
    }

    .duration {
      color: var(--surface-700);
      margin-left: 6px;
    }
  }

  .focus-on-courier-dropdown-item {
    display: flex;
    align-self: center;

    img {
      width: 20px;
      height: auto;
      margin-right: 6px;
    }
  }

  .more-info-btn {
    margin-top: 4px;
    padding: 2px 4px;
    font-size: 12px;
  }

  .leaflet-container a.leaflet-popup-close-button {
    top: 10px;
    right: 15px;
  }

  .leaflet-popup-content-wrapper,
  .leaflet-popup-tip {
    max-height: 260px;
    overflow-y: auto;
  }
}

.parcel-map-parcel-info-dialog {
  @include common;

  .info-parcel-type {
    color: var(--surface-700);
    margin-right: 4px;
  }

  .info-parcel-value {
    font-weight: bold;
  }
}
