.clients-view-dialog {
  &:not(.loading) {
    width: 600px;
    height: 90%;
  }

  svg {
    color: var(--bluegray-800);
  }

  table {
    width: 100%;

    tr {
      &:nth-child(even) {
        background: var(--bluegray-50);
      }

      &.section {
        background: white;

        td {
          padding: 0 0 8px 0;
        }

        h4 {
          margin: 24px 0 0 0;
          color: var(--blue-500);
        }
      }
    }

    td {
      margin: 0;
      padding: 6px 12px;

      &:not(:first-child) {
        text-align: center;
      }

      .icon {
        display: block;
        text-align: center;
      }
    }
  }

  .quick-info-container {
    display: flex;
    justify-content: space-around;

    .profile-picture {
      flex: 0.6;
      margin: 0 12px 0 6px;
      align-self: center;
      text-align: center;

      svg {
        margin-bottom: 6px;
        width: 36px;
        height: auto;
      }
    }

    .quick-info {
      margin-left: 12px;
      flex: 1;
      align-self: center;
      margin-bottom: 6px;

      & > div {
        margin: 6px 0;

        svg {
          margin-right: 6px;
          width: 16px;
        }
      }
    }
  }

  .discount-table {
    tr td:nth-child(2n) {
      padding-right: 36px;
    }
  }

  .indented-section {
    padding-left: 8px;
    padding-top: 4px;
  }

  .discount-table,
  .subscriptions-table {
    thead {
      font-weight: bold;

      th {
        &:first-child {
          text-align: left;
        }
      }
    }

    &:nth-child(even) {
      tr:nth-child(even) {
        background: white;
      }
    }

    &:nth-child(odd) {
      tr:nth-child(odd) {
        background: var(--bluegray-50);
      }

      tr:nth-child(even) {
        background: white;
      }
    }
  }
}
