.dashboard-page {
  .p-button {
    margin-bottom: 24px;
  }

  .react-grid-item.react-grid-placeholder {
    background-color: var(--bluegray-500);
    border-radius: 4px;
  }

  .chart-container {
    background: white;
    box-shadow: 0 5px 10px var(--bluegray-100), 0 2px 2px var(--bluegray-200);
    border-radius: 4px;
    overflow: hidden;

    .chart-header {
      background: var(--bluegray-700);
      color: white;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .chart-title {
        margin: 0 0 0 16px;
      }

      .chart-buttons {
        margin: 6px;
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.2s;
        display: flex;

        .chart-button {
          margin: 0 4px;
          cursor: pointer;
          color: var(--bluegray-100);
          border: 0;
          border-radius: 4px;
          width: 30px;
          height: 30px;
          text-align: center;
          transition: color 0.2s, background 0.2s;
          background: transparent;

          &:hover {
            color: white;
            background: var(--bluegray-600);
          }

          &:active {
            background: var(--bluegray-700);
          }

          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .chart-settings {
      padding: 12px 12px 6px 12px;
      display: flex;
      justify-content: space-around;

      .date-range-calendar-container {
        &.only-child {
          margin-left: auto;
        }
      }

      .icon {
        margin: 0 8px;
        color: var(--bluegray-600);
      }

      .p-dropdown,
      .p-inputtext,
      .p-multiselect {
        border-color: var(--bluegray-200);
        margin-right: 20px;

        &:not(:focus):hover {
          border-color: var(--gray-500);
        }
      }

      .p-multiselect {
        max-width: 200px;
        min-width: 160px;

        .p-multiselect-header {
          .p-checkbox .p-checkbox-box {
            display: none;
          }
        }

        .p-multiselect-panel .p-multiselect-header .p-checkbox,
        .p-multiselect-panel .p-multiselect-header .p-multiselect-close {
          display: none;
        }
      }
    }

    .chart {
      height: calc(100% - 50px);
      overflow: auto;

      .p-chart {
        padding: 0 12px;
      }
    }

    .draggable-handle {
      cursor: grab;

      &:active {
        cursor: grabbing;
      }
    }

    .cancel-drag {
      cursor: default;
    }

    .chart-container-no-data,
    .chart-container-loading,
    .chart-container-error {
      padding: 36px;
      text-align: center;
      color: var(--text-secondary-color);

      h3 {
        margin: 12px 0 6px 0;
      }
    }

    &:not(.is-loading):not(.resizing):hover {
      .chart-buttons {
        visibility: visible;
        opacity: 1;
      }
    }

    &.react-draggable-dragging {
      box-shadow: 0 5px 35px hsla(210, 18%, 13%, 0.2),
        0 3px 10px hsla(210, 18%, 13%, 0.15);
    }
  }
}
