$table: 900;
$contextMenu: 1000;
$overlay: 900;

$map: 950;
$mapOverlay: 951;

$baseDialog: 9000;

$sideMenu: 900;
$popUpSubMenu: 1001;
