.title {
  background-color: var(--surface-100);
  color: var(--surface-800);
}

.item {
  display: flex;
  a {
    width: 100%;
    text-align: left;
    color: var(--surface-700);
  }

  &:hover {
    background-color: var(--bluegray-50);
  }
}
