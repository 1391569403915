.dashboard-add-replace-chart-dialog {
  width: 360px;

  @media screen and (max-width: 767px) {
    width: 100%;
  }

  p {
    margin-top: 0;
  }

  .radio-button {
    margin-bottom: 12px;

    label {
      margin-left: 6px;
    }
  }
}
