.stepper-dialog {
  &:not(.p-dialog-maximized) {
    width: 720px;
    max-width: 90%;
  }

  .p-dialog-content {
    padding: 16px 24px 24px 24px;

    .section-subtitle {
      font-weight: 300;
      color: var(--surface-500);
    }

    .step {
      &:not(.full-width) {
        max-width: 360px;
      }
    }
  }

  .p-dialog-header {
    padding: 24px;

    .p-dialog-title {
      display: flex;
      align-items: center;

      .step-counter {
        margin-left: 30px;
        font-size: 14px;
        font-weight: normal;
        color: var(--surface-500);
      }
    }
  }

  .p-dialog-footer {
    padding: 24px;
    background-color: var(--bluegray-50);
    border-top: 1px solid var(--bluegray-100);
  }
}
