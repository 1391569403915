.flex {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: stretch !important;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: stretch !important;
}

.sidebar {
  flex-basis: 4rem;
  flex-grow: 1;
}

.mainContent {
  flex-basis: 0;
  flex-grow: 999;
  min-inline-size: 50%;
}
