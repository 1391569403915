.hubs-create-edit-dialog {
  width: 600px;
  max-width: 100%;
  height: auto;

  .p-inputtext,
  .p-multiselect,
  .p-dropdown {
    width: 100%;
  }

  .working-hours-wrapper {
    display: flex;
    align-items: center;
  }

  .divider {
    margin: 0 8px;
  }

  .section-title {
    margin-bottom: 24px;
    display: block;
    font-weight: bold;
  }

  .street-wrapper {
    display: flex;
    align-items: center;

    .street {
      width: 100%;
      flex: 1;
    }

    .street-no {
      margin-left: 14px;
      width: 72px;
    }
  }

  .general-info,
  .address {
    width: 360px;
    max-width: 100%;
  }

  .show-section {
    display: block;
    color: var(--red-500);

    span {
      margin-left: 6px;
    }
  }

  .hide-section {
    display: none;
  }
}
