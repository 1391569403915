.hub-report-cart {
  border: 2px solid var(--bluegray-100);
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 3px;
  cursor: pointer;
  background-color: var(--bluegray-50);
  box-shadow: -1px 0px 4px 0px lightgray;
  color: var(--bluegray-900);

  &:hover {
    background-color: var(--bluegray-100);
  }

  .hub-report-cart-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--bluegray-200);
    margin-bottom: 8px;
    h2 {
      padding: 0px;
      margin: 0px 16px 0px 10px;
      @media (max-width: 1200px) {
        font-size: small;
        margin: 0px 10px 0px 2px;
      }
    }
  }

  .hub-reports-address-span {
    font-weight: 600;
  }

  .hub-report-cart-details {
    display: flex;
    gap: 15px;
    .hub-report-label {
      font-weight: 600;
    }
    @media (max-width: 1200px) {
      font-size: x-small;
      font-weight: 400;
    }
  }

  .hub-reports {
    display: flex;
    gap: 2px;
    width: 100%;
    table {
      border-collapse: collapse;
    }
    tr {
      border: 1px solid var(--bluegray-200);
      padding: 2px;
    }
    th {
      border: 1px solid var(--bluegray-200);
      font-weight: 600;
      padding: 2px;
      text-align: center;
    }
    td {
      padding: 3px;
    }
    td:nth-child(2n) {
      border-right: 1px solid var(--bluegray-200);
    }
    .hub-report {
      display: flex;
      width: 100%;
      justify-content: center;
      .hub-reports-table {
        display: table;
        width: 100%;

        .report-table-same {
          color: var(--blue-700);
        }
        .report-table-different {
          color: var(--red-700);
        }
      }
    }
    @media only screen and (max-width: 600px) {
      flex-direction: column;
      font-size: x-small;
      margin-bottom: 5px;
      padding: 0px;
      .th-title {
        font-weight: 600;
      }
      th {
        padding: 0px;
      }
      td {
        padding: 0px;
      }
    }
    @media (min-width: 600px) and (max-width: 1200px) {
      flex-direction: column;
      font-size: small;
      .th-title {
        font-weight: 600;
      }
      th {
        border: 1px solid var(--bluegray-200);
        font-weight: 400;
        padding: 1px;
      }
      td {
        padding: 1px;
      }
    }
  }
}
