.discountsGrid {
  display: grid;
  grid-template-columns:
    min-content minmax(auto, 25%) minmax(auto, 25%) minmax(auto, 25%)
    minmax(auto, 15%) min-content;
  column-gap: 0.5rem;
  row-gap: 0.5rem;
  align-items: baseline;

  // Button
  &:nth-last-child(-n + 6) div {
    align-self: center;
  }
}

.emptyContent {
  margin: 24px 0 0 0;
  display: block;
  text-align: center;

  p {
    font-size: 16px;
  }

  img {
    width: 300px;
  }
}

.arrowUp {
  width: auto;
  height: 160px;
  margin: 1rem 0 0.5rem 80px;
  fill: var(--surface-300);
}
