.btn-add {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 10px;

  .main-btn {
    margin-left: auto;
  }
}

.rules-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.rules {
  display: flex;
  gap: 4px;
}
