.courier-compensation-page {
  .more-info-section {
    max-width: 300px;
    min-width: 250px;
  }

  .row-expanded-section-labels {
    text-transform: uppercase;
    color: var(--blue-500);
  }

  .row-cell {
    color: var(--bluegray-600);
    font-weight: bold;
  }
}
