.list-types-view-dialog {
  width: 720px;

  .quick-info-container {
    display: flex;
    justify-content: space-around;

    .profile-picture {
      flex: 0.6;
      margin: 0 12px 0 6px;
      align-self: center;
      text-align: center;

      svg {
        margin-bottom: 6px;
        width: 36px;
        height: auto;
      }
    }

    .quick-info {
      margin-left: 12px;
      flex: 1;
      align-self: center;
      margin-bottom: 6px;

      & > div {
        margin: 6px 0;

        svg {
          margin-right: 6px;
          width: 16px;
        }
      }
    }
  }

  h4 {
    margin: 24px 0 4px 0;
    color: var(--blue-500);
  }

  table {
    width: 100%;

    &.payment-configuration-table,
    &.price-list-configuration-table {
      table-layout: fixed;
    }

    thead {
      font-weight: bold;

      th {
        &:first-child {
          text-align: left;
          padding-left: 6px;
        }
      }
    }

    tr {
      &:nth-child(even) {
        background: var(--bluegray-50);
      }
    }

    td {
      margin: 0;
      padding: 6px 12px;

      &:not(:first-child) {
        text-align: center;
      }

      .icon {
        display: block;
        text-align: center;
      }
    }
  }

  svg {
    color: var(--bluegray-800);
  }
}
