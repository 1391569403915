.error-container {
  margin: 48px auto 0 auto;
  padding: 60px 24px;
  width: 720px;
  text-align: center;
  background: white;
  border-radius: 3px;

  h1 {
    margin: 0;
    font-size: 36px;
  }

  p {
    color: var(--text-color-secondary);
    margin: 12px 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  img {
    margin: 16px 0;
    width: 360px;
    height: auto;
  }

  a {
    color: var(--blue-500);
    text-decoration: underline;
  }
}
