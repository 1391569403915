.regions-view-courier-dialog {
  height: auto;
  width: 480px;
  max-width: 100%;

  .courier-assignments {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    gap: 8px 0;
    justify-items: center;

    & > span {
      justify-self: end;

      &:nth-child(2),
      &:nth-child(3) {
        justify-self: center;
      }
    }
  }

  .info-item {
    margin-bottom: 16px;

    h4 {
      margin: 0 0 4px 0;
      color: var(--blue-500);
    }
  }
}
