.barcode {
  margin-bottom: 6px;
  padding: 6px;
  background: #fff;
  border: 1px solid var(--surface-d);
  border-radius: 3px;

  .barcodeTitle {
    padding: 0;
    margin: 5px 2px;
    display: flex;
    gap: 10px;
    align-items: center;
  }
}
