.regions-page .sidebar {
  padding: 16px;
  background: white;
  border-radius: 3px;

  .p-fluid {
    &:not(:first-child) {
      margin-top: 16px;
    }

    .p-field {
      margin-bottom: 4px;
    }
  }

  .action-buttons {
    display: flex;
    padding: 4px 0;

    .p-button {
      flex: 1;

      &:not(:last-child) {
        margin-right: 4px;
      }
    }
  }

  .no-couriers-assigned {
    margin: 14px 0 0 0;
    color: var(--bluegray-500);
    text-align: center;

    svg {
      margin-right: 4px;
    }
  }
}
