.hubs-view-dialog {
  &:not(.loading) {
    width: 600px;
  }

  .section-title {
    display: block;
    margin: 12px 0;
    color: var(--blue-500);
    font-weight: 600;
  }

  .quick-info-container {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    align-content: flex-start;

    .quick-info {
      margin-left: 12px;
      flex: 1;
      margin-bottom: 6px;

      & > div {
        margin: 6px 0;
      }

      svg {
        margin-right: 8px;
        width: 16px;
      }
    }
  }

  .total-regions {
    display: block;
    font-weight: 600;
    margin: 10px 0;
  }

  .regions-info-table {
    margin: 6px 0 12px 12px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr auto;
    grid-gap: 10px;
  }

  .map {
    width: 100%;
    min-height: 200px;

    .leaflet-marker-icon {
      cursor: auto;
    }
  }

  .map,
  .no-location-available {
    margin-bottom: 18px;
  }

  .no-location-available {
    padding-left: 12px;
  }
}
