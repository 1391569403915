@use './shared';
@use '../../../styles/z-index.scss';

.contextMenu {
  @include shared.menuItem;

  position: absolute;
  z-index: z-index.$contextMenu;

  // Even though this scenario is handled in the component itself,
  //  a state change from model.length > 0 to model.lenth === 0
  //  will render an empty menu. Not ideal.
  &:empty {
    visibility: hidden;
  }
}
