.notifications-tab {
  h3 {
    margin-bottom: 8px;
  }

  textarea {
    width: 100%;
    height: 140px;
    resize: none;
    white-space: normal !important;
  }

  .p-editor-toolbar {
    display: none;
  }

  .p-tree {
    padding: 0;
    border: none;
    border-radius: 0;
  }

  .p-tree-container {
    padding: 0;
  }

  .p-treenode-label {
    width: calc(100% - 26px) !important;
  }

  ul.p-tree-container > li:first-child .p-treenode-content {
    background-color: var(--bluegray-50);
    font-weight: bold;
  }

  .p-treenode-content {
    .notification-info-icon {
      opacity: 0;
      transition: opacity 0.17s;
    }

    &:hover {
      .notification-info-icon {
        opacity: 1;
      }
    }
  }

  .notification-tree-column-container {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .notification-tree-column {
    &.notification-info-column {
      flex-basis: 30px;
      color: var(--bluegray-600);
    }

    &:not(.notification-info-column) {
      flex-basis: 20%;
    }

    &:first-child {
      flex-basis: 200px;
    }

    &:not(:first-child) {
      text-align: center;
    }
  }

  .placeholder-list {
    margin: 0;
    padding: 0;

    li {
      margin: 0;
      padding: 0;
    }
  }

  .notification-settings-title {
    margin-top: 24px;
    margin-bottom: 0;
  }

  .notification-settings-info {
    margin: 8px 0;
    font-size: 12px;
  }
}

.notification-tab-checkbox-field {
  display: grid;
  grid-template-columns: auto auto;
}
