.shipment-locations {
  background-color: white;
  height: calc(100vh - 348px);
  overflow-y: scroll;

  .p-panel .p-component .p-panel-toggleable {
    border: none;
  }
  .p-panel {
    .p-panel-content {
      border: none;
    }
  }
}

.location {
  padding: 4px;
  display: flex;
  justify-content: flex-start;
  color: var(--surface-700);
  cursor: pointer;

  .fa-user-alt {
    color: var(--blue-300);
    width: 20px;
    margin-right: 10px;
  }

  .fa-users {
    color: var(--green-300);
    width: 20px;
    margin-right: 10px;
  }

  .fa-warehouse {
    color: var(--yellow-300);
    width: 20px;
    margin-right: 10px;
  }

  .fa-chevron-right,
  .fa-chevron-down {
    width: 20px;
    margin-right: 10px;
  }

  &:hover {
    color: var(--bluegray-500);
    background-color: var(--surface-50);
  }
}

.selected-location {
  color: var(--bluegray-900);
  background-color: var(--surface-50);
  font-weight: 500;
}
