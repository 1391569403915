.create-edit-parcel-dialog {
  width: 1300px;
  height: 90%;

  @media (max-width: 1000px) {
    width: 600px;
  }

  .p-dialog-title,
  .p-panel-title {
    width: 100%;
  }

  .p-dialog-content {
    height: 100%;
  }

  .constant {
    position: absolute;
    width: 100%;
    height: calc(100% - 50px);
    top: 50px;
    left: 0;
    background: rgba(255, 255, 255, 0.356);
    z-index: 1001;
  }

  .p-panel {
    position: relative;
  }

  .p-dialog-footer {
    padding-top: 1.5rem;
    position: relative;

    .group-buttons {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 20px;
      top: calc(50% - 16px);
    }
  }

  .p-dialog-title {
    position: relative;

    .group-buttons {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      font-size: 14px;
      font-weight: lighter;
    }
  }

  .p-panel-content {
    padding: 20px 30px;
  }

  .remove-orderer {
    background-color: white;
    border: none;
    padding: 5px;
    font-size: 16px;
    font-weight: 100;

    &:hover {
      background-color: var(--surface-100);
      cursor: pointer;
    }
  }

  .daily-timeframe {
    display: flex;
    justify-content: center;
    align-items: center;

    .divider {
      margin: 0 8px;
    }

    .p-calendar {
      width: 100%;
    }
  }

  .toggle-orderer {
    background: rgb(248, 249, 250);
    border: 1px solid var(--surface-300);
    color: var(--surface-700);

    &:hover {
      background-color: var(--surface-200) !important;
      color: var(--surface-800) !important;
    }
  }

  .attributes {
    padding-left: 10px;
    width: 30%;

    .p-panel-content {
      padding: 20px;
    }
  }

  .tabs {
    width: 70%;
    display: flex;
    justify-content: center;

    .sender {
      width: 83%;

      .tab {
        display: flex;

        > * {
          padding-right: 10px;
        }
      }
    }

    .orderer {
      width: 17%;
    }

    &.sender-active {
      > div,
      .tab {
        transition: all 150ms linear;
      }

      .sender {
        width: 80%;

        .tab {
          width: 100%;
          transform: scale(1);
          margin-left: 0;
        }
      }

      .orderer {
        width: 20%;

        .tab {
          transform: scale(0.5);
          transform-origin: top;
          margin-left: -50%;

          width: 200%;
        }

        &:hover {
          .tab {
            transform: scale(0.53);
          }
        }
      }
    }

    &.orderer-active {
      > div,
      .tab {
        transition: all 150ms linear;
      }

      .sender {
        width: 60%;

        .tab {
          transform: scale(0.55);
          width: 150%;
          margin-left: -25%;
          transform-origin: top;
        }

        &:hover {
          .tab {
            transform: scale(0.6);
          }
        }
      }

      .orderer {
        width: 40%;

        .tab {
          width: 100%;
          transform: scale(1);
          margin-left: 0;
        }
      }
    }
  }

  .p-inputtext,
  .p-inputtextarea,
  .p-multiselect,
  .p-autocomplete,
  .p-dropdown {
    width: 100%;
  }

  .payments {
    width: 100%;
    padding: 20px;
    margin-top: 20px;
    border: 1px solid #dee2e6;
  }

  table {
    width: 100%;
    border: 2px solid var(--bluegray-50);

    th {
      padding: 8px 12px;
      background: var(--bluegray-50);

      &:first-child {
        text-align: left;
      }
    }

    td {
      margin: 0;
      padding: 6px 12px;
      text-align: left;

      &:not(:first-child) {
        text-align: center;
      }
    }

    tr {
      &:nth-child(even) {
        background: var(--bluegray-50);
      }
    }
  }

  .calculator-spinner {
    width: 20px;
    height: 20px;
  }

  .hub-checkbox {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
  }

  .hub-inputtext {
    width: 90%;
  }

  .pickup-assignment {
    margin-top: 20px;
    width: 100%;

    &-container {
      padding: 20px;
      border: 1px solid #dee2e6;
    }

    &-checkbox {
      display: flex;
      align-items: center;
      margin-bottom: 4px;
    }

    &-dropdown {
      max-width: 320px;
    }
  }
}
